<template>
  <div>
    <div class="m-1" :class="{'hidden': isSuccess}">
      <b-card>
        <div class="mb-3">
          <router-link class="lnk link-primary" :to="{ name: 'marketcenter' }">
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </router-link>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <img :src="marketcenter.icon" alt="logo integrartion" width="115px">
            &nbsp;<span class="text-warning">{{edit ? 'Edita tu configuración de Marketcener' : 'Conecta tu negocio al canal de ventas'}}</span>
          </div>
          <div class="d-flex align-items-center">
            <feather-icon icon="SettingsIcon" size="14" style="color:#044389"/>&nbsp;<a :href="marketcenter.documentation" target="_blank" style="color:#044389;font-size:8px;font-weight:bolder;">GUÍA DE CONFIGURACIÓN</a>
          </div>
        </div>
        <div class="border-top"></div>
        <div v-if="!edit">
          <div class="p-2" v-if="isMeliIntegration">
            <card-item>
              <div class="spacing">
                <span class="text-primary title">Paso 1</span>
                &nbsp;<span class="text-primary" style="font-size:16px">Redirige a tu cuenta de Mercado Libre para iniciar la configuración.</span>
                <b-button v-if="!marketcenter.is_integrated" variant="outline-warning ml-1" @click="goRedirectMeli">IR A MERCADO LIBRE</b-button>
              </div>
              <div class="border-top"></div>
              <div class="spacing">
                <ul>
                  <li>Haz clic en el botón <b class="text-primary">'Ir a Mercado Libre'</b> para ser redirigido a la página de inicio de sesión de Mercado Libre. Inicia sesión con tu usuario y contraseña de administrador. Una vez que hayas iniciado sesión, autoriza a Envíame para conectarse con tu cuenta de Mercado Libre.</li>
                  <li>Después de autorizar, regresa a esta página y selecciona el botón <b class="text-primary">'Validar Autenticación'</b> para completar la configuración de la integración.</li>
                </ul>
              </div>
            </card-item>
            <div class="d-flex justify-content-end mt-4">
              <div class="d-flex align-items-center">
                <span>Utiliza el botón <b class="text-primary">'Validar Autenticación'</b> para finalizar la integración.</span>
              </div>
              <div>
                <b-button class="ml-1" variant="warning" :disabled="!marketcenter.is_integrated" @click="goSuccessView">VALIDAR AUTENTICACION</b-button>
              </div>
            </div>
          </div>
          <div class="p-2" v-if="!isMeliIntegration">
            <card-item>
              <div class="spacing">
                <span class="text-primary title">Paso 1</span>
                &nbsp;<span class="text-primary" style="font-size:16px">Configura los selectores eligiendo las opciones correspondientes.</span>
              </div>
              <div class="border-top"></div>
              <div class="spacing" v-if="!loading.fields">
                <ul>
                  <li v-for="item in filterCustomization" :key="item.field">
                    <b>{{item.label}}:</b>&nbsp;{{item.description}}
                  </li>
                </ul>
                <div class="mt-2">
                  <form-render 
                    :form.sync="form" 
                    :key="keyformIntegration" 
                    :fields="fields"              
                    ref="keyformIntegration"
                    containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
                  </form-render>
                </div>
              </div>
              <div v-if="loading.fields" class="d-flex justify-content-center align-items-center" style="min-height:14em">
                <b-spinner variant="primary"></b-spinner>
              </div>
            </card-item>
            <div class="d-flex justify-content-end">
              <div>
                <b-button class="mt-4" variant="warning" @click="postMarketcenter" :disabled="loading.post">
                  <b-spinner v-show="loading.post" small></b-spinner>&nbsp;CONECTAR</b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="edit" class="p-2">
          <card-item>
              <div class="spacing">
                <span class="text-primary title">Paso 1</span>
                &nbsp;<span class="text-primary" style="font-size:16px">Ajusta y completa los campos de información que consideres pertinente.</span>
              </div>
              <div class="border-top"></div>
              <div class="spacing pt-2" v-if="!loading.fields">
                <form-render
                  id="editForm"
                  :form.sync="form"
                  :key="keyEditFormIntegration"
                  :fields="fields"
                  ref="keyEditFormIntegration"
                  containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
                </form-render>
              </div>
              <div v-if="loading.fields" class="d-flex justify-content-center align-items-center" style="min-height:31em">
                <b-spinner variant="primary"></b-spinner>
              </div>
            </card-item>
            <div class="mt-3 d-flex justify-content-end">
              <b-button variant="outline-warning mr-1" @click="goMarketcenter">CANCELAR</b-button>
              <b-button variant="warning" @click="postMarketcenter" :disabled="loading.post"><b-spinner v-show="loading.post" small></b-spinner>&nbsp;GUARDAR CAMBIOS</b-button>
            </div>
        </div>
      </b-card>
    </div>
    <div class="m-1" :class="{'hidden': !isSuccess}">
      <b-card>
        <div class="mt-3" >
          <div class="d-flex justify-content-center align-items-center">
            <span class="mr-1"><img style="width: 12rem;" src="https://storage.googleapis.com/marketcenter-labels-local/logo_enviame.svg" alt="Logo Enviame"></span>
            <span class="line"></span>
            <span class="ml-1"><img style="width: 12rem;" src="https://storage.googleapis.com/marketcenter-labels/logo_meli.png" alt="Logo Tiendanube"></span>
          </div>
          <div class="text-center mt-3">
            <span style="font-size:24px;font-weight:bold"><feather-icon icon="CheckCircleIcon" size="24" class="text-success"/> ¡Integración exitosa!</span>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <span class="w-60">Para ver el detalle de la integración, te recomendamos ingresar a la configuración del canal de venta. Revísalo y, si es necesario, personaliza los datos predeterminados proporcionados por Mercado Libre.</span>
          </div>
          <div class="d-flex justify-content-center mt-3" style="margin-bottom:15%">
            <b-button class="mr-2" variant="outline-warning" style="width:250px" @click="goEdit">IR A LA CONFIGURACIÓN</b-button>
            <b-button variant="warning" style="width:250px" @click="goMarketcenter">IR AL INICIO</b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import environment from '@/environments/environment'
import { mapGetters } from 'vuex'
import CardItem from '../components/CardItem.vue'
export default {
  components: { CardItem },
  data() {
    return {
      keyformIntegration: 0,
      keyEditFormIntegration: 0,
      isSuccess: false,
      form: {},
      loading: {
        fields: false,
        post: false
      },
      backupFields: Object.freeze({
        flb: {
          api_key_falabella: {
            config: true,
            description: 'Importa la "API Key" que has copiado en el campo "API Key" desde la pestaña API de tu cuenta de SellerCenter de Falabella.'
          },
          user_id: {
            config: true,
            description: 'Importa el correo que has copiado en el campo "User ID" desde la pestaña API de tu cuenta de SellerCenter de Falabella.'
          }
        },
        daf: {
          application_id: {
            config: true,
            description: 'Importa la "API Key" que has copiado en el campo "API Key" desde la pestaña API de tu cuenta de SellerCenter de Dafiti. '
          },
          application_secret: {
            config: true,
            description: 'Importa el correo que has copiado en el campo "User ID" desde la pestaña API de tu cuenta de SellerCenter de Dafiti.'
          }
        },
        prm: {
          seller_shipper_id: {
            config: true,
            description: 'Importa el "ID" que has copiado en el campo "ID" desde la pestaña Info. de la empresa de tu cuenta Seller asociada a la cuenta de Cencosud en Envíame.'
          },
          api_key_enviame: {
            config: true,
            description: 'Importa la activación de webhook previamente solicitada a Support de Envíame o tu Ejecutivo Comercial.'
          }
        },
        rpl: {
          username: {
            config: true,
            description: 'Importa el correo que has copiado en el campo "Nombre de Usuario" desde tu cuenta de SellerCenter de Ripley.'
          },
          password: {
            config: true,
            description: 'Importa la contraseña que has copiado en el campo "Contraseña" desde tu cuenta de SellerCenter de Ripley.'
          }
        },
        hts: {
          seller_shipper_id: {
            config: true,
            description: 'Importa el "ID" que has copiado en el campo "ID" desde la pestaña Info. de la empresa de tu cuenta Seller asociada a la cuenta de Envíame.'
          },
          api_key_enviame: {
            config: true,
            description: 'Importa la activación de webhook previamente solicitada a Support de Envíame o tu Ejecutivo Comercial.'
          }
        },
        kc: {
          seller_shipper_id: {
            config: true,
            description: 'Importa el "ID" que has copiado en el campo "ID" desde la pestaña Info. de la empresa de tu cuenta Seller asociada a la cuenta de Envíame.'
          },
          api_key_enviame: {
            config: true,
            description: 'Importa la activación de webhook previamente solicitada a Support de Envíame o tu Ejecutivo Comercial.'
          }
        },
        wm: {
          seller_shipper_id: {
            config: true,
            description: 'Importa el "ID" que has copiado en el campo "ID" desde la pestaña Info. de la empresa de tu cuenta Seller asociada a la cuenta de Envíame.'
          },
          api_key_enviame: {
            config: true,
            description: 'Importa la activación de webhook previamente solicitada a Support de Envíame o tu Ejecutivo Comercial.'
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      generalLoading: 'getLoading',
      marketcenters: 'getMarketcenters',
      warehouses: 'getWarehouses',
      fieldsMarketcenter: 'getFieldsMarketcenter'
    }),
    shipper_id() {
      return this.$route.params.shipper_id
    },
    marketcenter_code() {
      return this.$route.params.marketcenter_code
    },
    marketcenter() {
      return this.marketcenters.find((mkc) => mkc.code === this.marketcenter_code)
    },
    myWarehouses() {
      return this.warehouses?.map((ware) => ({...ware, id: ware.code, text: ware.name}))
    },
    isMeliIntegration() {
      const regex = /^ML/gm
      return Boolean(this.marketcenter.code.match(regex))
    },
    customization() {
      const customAdd = this.backupFields[`${this.marketcenter_code.split('_')[0].toLowerCase()}`] ?? {}
      return this.fieldsMarketcenter.map((custom) => ({...custom, ...customAdd[`${custom.field}`]})) ?? []
    },
    filterCustomization() {
      return this.customization.filter((custom) => custom.config) ?? []
    },
    edit() {
      return this.$route.params.edit === 'edit'
    },
    fields() {
      const fields = this.edit ? this.customization : this.filterCustomization
      return fields.map(({field, label, type, ...rest}) => {
        const typeField = this.getCustomizedField(field, label, type, rest)
        return {
          ...typeField,
          name: field,
          containerClass: 'col-lg-3 col-md-4 col-sm-12 container-info',
          validation: this.edit ? rest?.validation : rest?.config ? 'required' : ''
        }
      }) ?? []
    },
    defaultWarehouse() {
      const [warehouse] = this.myWarehouses
      return warehouse
    },
    meliRedirectBaseUrl() {
      return environment.environment.ottawaMeliRedirect
    }
  },
  watch: {
    fields() {
      const fields = this.customization
      this.form = fields.reduce((target, key) => {
        const value = this.getValuesForm(key)
        target[key.field] = value
        return target
      }, {})
    },
    generalLoading: {
      handler () {
        this.loading.fields = !!this.generalLoading.getFieldsMarketcenter
        this.loading.post = !!this.generalLoading.postConfigMarketcenter
      },
      deep: true
    }
  },
  mounted() {
    this.getFieldsMarketcenter()
    if (!Boolean(this.marketcenters.length)) this.getMarketcenters()
    if (!Boolean(this.warehouses.length)) this.getWarehouses()
  },
  methods: {
    goRedirectMeli() {
      const api_key = this.customization.find((custom) => custom.field === 'api_key_enviame').value
      const urlMeli = `${this.meliRedirectBaseUrl}/webhook/mercado-libre/authenticate?api_key=${api_key}&company=${this.shipper_id}&warehouse_code=${this.warehouses[0]?.code}&marketcenter_code=${this.marketcenter_code}`
      window.parent.location.replace(urlMeli)
    },
    getCustomizedField (field, label, type, rest) {
      switch (type) {
      case 'checkbox':
        return { fieldType:'FieldCheckbox', options: [{id: true, text: label}]}
      case 'select':
        return field === 'warehouse_code' ? { fieldType:'FieldSelect', options: this.myWarehouses, label} : { fieldType:'FieldSelect', options: rest.options.map((op) => ({ id: op.code, text: op.name })), label}
      case 'multiselect':
        if (rest?.message) return { fieldType:'FieldSelect', options: rest?.options?.map((op) => ({ id: op.value, text: op.title })) || [], label, multiple: true, info: `<div class="alert"> <i class="fa fa-info-circle text-primary"></i> ${rest?.message}</div >` }
        return { fieldType:'FieldSelect', options: rest?.options?.map((op) => ({ id: op.value, text: op.title })) || [], label, multiple: true }
      case 'textarea':
        return { fieldType:'FieldTextarea', label}
      default:
        return { fieldType: 'FieldInput', label}
      }
    },
    getValuesForm (key) {
      switch (key.type) {
      case 'checkbox':
        return !!key.value
      case 'select':
        return key.field === 'warehouse_code' ? this.myWarehouses?.find((w) => w.value === key.id) ?? {} : key.options?.map((el) => ({id: el.code, text: el.name}))?.find((w) => w.id === key.value)
      case 'multiselect':
        return key.options.filter((v) => key.value.some((value) => value === v.value)).map((v) => ({id: v.value, text:v.title})) || []
      case 'textarea':
        return key.value
      default:
        return key.value
      }
    },
    getPostValuesForm (field, form) {
      switch (field.type) {
      case 'checkbox':
        return !!form[field.field]
      case 'select':
        return form[field.field]?.id ?? ''
      case 'multiselect':
        return form[field.field]?.map((v) => v.id) || []
      default:
        return form[field.field] ?? ''
      }
    },
    async postMarketcenter() {
      const form = this.edit ? this.$refs.keyEditFormIntegration : this.$refs.keyformIntegration
      if (!await form.onlyCheckForm()) return
      const queryParams = this.customization.map((field) => {
        //eliminar cuando este listo el back
        const { config, description, ...payload } = field
        if (!this.edit) this.form.warehouse_code = this.defaultWarehouse
        const newValue = this.getPostValuesForm(field, this.form)
        return {
          ...payload,
          value: newValue
        }        
      })
      const data = { 
        name: 'postConfigMarketcenter',
        params: {marketcenter_cod: this.marketcenter_code, shipper_id: this.shipper_id},
        queryParams,
        onSuccess: () => {
          this.getMarketcenters()
          this.$router.push({ name: 'marketcenter' })
        }
      }
      console.log('qry', data.queryParams)
      this.$store.dispatch('fetchService', data)
    },
    getMarketcenters() {
      this.$store.dispatch('dispatchSetParamsMkc', { shipper_id: this.shipper_id, marketcenter_code: this.marketcenter_code })
      const data = {
        name: 'getMarketcenters',
        params: { shipper_id: this.shipper_id }
      }
      this.$store.dispatch('fetchService', data)
    },
    getFieldsMarketcenter() {
      const data = { 
        name: 'getFieldsMarketcenter',
        params: {marketcenter_cod: this.marketcenter_code, shipper_id: this.shipper_id}
      }
      this.$store.dispatch('fetchService', data)
    },
    getWarehouses() {
      const data = { 
        name: 'getWarehouses',
        params: { shipper_id: this.shipper_id }
      }
      this.$store.dispatch('fetchService', data)
    },
    goSuccessView() {
      this.isSuccess = true
    },
    goEdit() {
      this.$router.push({ name: 'marketcenter-detail', params: { shipper_id: this.shipper_id, marketcenter_code: this.marketcenter_code, edit: 'edit' }})
      this.isSuccess = false
    },
    goMarketcenter() {
      this.$router.push({ name: 'marketcenter' })
    }
  }
}
</script>
<style>
.title {
  font-size: 30px;
  font-weight: bolder;
}
li::marker {
  color: #044389;
  font-size: 1.6em;
}
.spacing {
  padding: 1rem 2rem;
}
.line {
  border-left: 2px solid #091E3F;
  height:50px;
}
#editForm span form .form-control {
  background-color: inherit;
}
#editForm span form .vs__dropdown-toggle {
   background-color: #F2F2F8;
}
#editForm span form div[fieldtype=FieldSelect] {
  background-color: inherit !important;
}
#editForm span form .container-info {
  min-height: 6rem;
}
.alert {
  margin-top: 5px;
  padding: 10px;
  background-color: #E6EEFA;
  border: #D0E2F8 2px solid;
}
</style>